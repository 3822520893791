// @flow
import React, { Component } from 'react';
import { authLocalStore, credentials } from '@shootsta/client-auth';
import { replaceBucketKeysWithUrls } from '@shootsta/client-aws-s3';
import {
  CreditsInfo,
  Icon,
  Image,
  withFlagsConsumer
} from '@shootsta/common-react';
import type { RouterHistory as History } from 'react-router-dom';
import type { User } from '@shootsta/common-flow-types';
import NotificationsSummary from './NotificationsSummary';
import UserWrapper from './UserWrapper';
import SwitchOrganisationModal from './SwitchOrganisationModal';
import { clearSSOLocalStore } from '../../../../utils';
import { handleSignOut } from '../../../utils';
import SHOOTSTA_LOGO_ICON from '../../../../assets/images/shootsta-logo-icon.png';
// $FlowFixMe
import { openHubSpotConversationsWidget } from '@core/shared';

import '../styles/_global-header.scss';

type GlobalHeaderProps = {
  showSwitchOrganisationModal: Function,
  appSwitcherVisible: boolean,
  onCloseSwitchOrganisationModal: Function,
  apolloClient: Object,
  selectedOrganisation: Object,
  notifications: Object,
  history: History,
  adminMode: boolean,
  toggleAdminMode: Function,
  ghostStop: Function,
  getFeedbackLink: Function,
  loginViaIdentity: boolean,
  width: number,
  flags: any
};

type GlobalHeaderState = {
  userId: string,
  fullName: string,
  imageUrl: string,
  email: string,
  feedbackLinks?: Object,
  isGhosting: boolean,
  original: Object,
  origURL: string,
  orgImageUrl: string | null
};

const MAX_FULL_HEADER_WIDTH = 500;

class GlobalHeader extends Component<GlobalHeaderProps, GlobalHeaderState> {
  constructor(props: GlobalHeaderProps) {
    super(props);

    const { userId, firstName, lastName, imageUrl, email } =
      credentials.get().user;
    const { original = null, origURL = '' } = credentials.get();
    credentials.listenToChange(this.handleCredentialsChange);

    const fullName = `${firstName} ${lastName}`;

    this.state = {
      userId,
      fullName,
      imageUrl,
      orgImageUrl: null,
      email,
      feedbackLinks: null,
      isGhosting: Boolean(original),
      original,
      origURL
    };
  }

  componentDidMount() {
    const { flags } = this.props;
    this.getPreSignedImageUrl();
    this.getPreSignedOrgImageUrl();
    if (flags.enableFeedbackLinks) {
      this.fetchFeedbackLinks();
    }
  }

  initialiseChangelog = (el: HTMLDivElement | null) => {
    if (!el) {
      return;
    }

    const { width } = this.props;

    if (width < MAX_FULL_HEADER_WIDTH) {
      return;
    }

    window.Canny('initChangelog', {
      appID: '5f4d9dcea4f8144185c24398',
      position: 'bottom',
      align: 'right'
    });
  };

  fetchFeedbackLinks = async () => {
    const { getFeedbackLink } = this.props;
    try {
      const payload = ['topbarFeedbackIconLink'];
      const { data } = await getFeedbackLink(payload);
      this.setState({ feedbackLinks: data?.getFeedbackLinks });
    } catch (error) {
      console.error(error);
    }
  };

  getPreSignedImageUrl = async () => {
    const {
      selectedOrganisation: { organisationId },
      apolloClient
    } = this.props;
    const { imageUrl } = this.state;

    try {
      const { imageUrl: preSignedImageUrl } = await replaceBucketKeysWithUrls({
        apiClient: apolloClient,
        data: { imageUrl },
        substitutedKeys: ['imageUrl'],
        organisationId
      });

      this.setState({ imageUrl: preSignedImageUrl });
    } catch {
      // Silently handle error
    }
  };

  getPreSignedOrgImageUrl = async () => {
    const {
      selectedOrganisation: { organisationId, imageUrl },
      apolloClient
    } = this.props;

    try {
      const { imageUrl: preSignedOrgImageUrl } =
        await replaceBucketKeysWithUrls({
          apiClient: apolloClient,
          data: { imageUrl },
          substitutedKeys: ['imageUrl'],
          organisationId
        });

      this.setState({ orgImageUrl: preSignedOrgImageUrl });
    } catch {
      // Silently handle error
    }
  };

  handleCredentialsChange = (userCredentials?: { user: ?User }) => {
    if (!userCredentials || !userCredentials.user) {
      return;
    }
    const { user } = userCredentials;
    this.setState(
      {
        ...user,
        fullName: `${user.firstName} ${user.lastName}`
      },
      this.getPreSignedImageUrl
    );
  };

  handleSignOut = () => {
    const { isGhosting } = this.state;
    const { history, loginViaIdentity } = this.props;

    return handleSignOut({
      isGhosting,
      history,
      loginViaIdentity
    });
  };

  handleStopGhosting = async () => {
    const { ghostStop, history, loginViaIdentity } = this.props;
    const {
      original: { token, user, original = null },
      origURL
    } = this.state;

    clearSSOLocalStore();

    if (!loginViaIdentity) {
      return void history.push(
        `/?ghost=${btoa(JSON.stringify({ clearGhost: true, token, user, original, targetURL: origURL }))}`
      );
    }

    await ghostStop();

    const data = {
      auth: true,
      ghost: false,
      token,
      user,
      original,
      origURL
    };

    authLocalStore.save(data);

    window.location.replace(origURL);
  };

  isProWorkflow = () => {
    return (
      location.pathname.includes('/pro/builder/') &&
      !location.pathname.includes('/pro/builder/new')
    );
  };

  renderContent() {
    const { width, flags } = this.props;
    const { feedbackLinks } = this.state;

    if (width < MAX_FULL_HEADER_WIDTH) {
      return null;
    }

    if (flags.enableFeedbackLinks && feedbackLinks?.topbarFeedbackIconLink) {
      return (
        <div className="global-header-container__changes-container">
          <Icon
            tooltipId="feedback"
            name="feedback"
            tooltipLabel="Feedback"
            size={20}
            tooltipPlace="bottom"
            onClick={() =>
              window.open(feedbackLinks.topbarFeedbackIconLink, '_blank')
            }
          />
        </div>
      );
    }

    return (
      <div
        className="global-header-container__changes-container"
        data-canny-changelog
        ref={this.initialiseChangelog}
      >
        <Icon name="flashon" size={20} />
      </div>
    );
  }

  render() {
    const {
      userId,
      fullName,
      imageUrl,
      email,
      feedbackLinks,
      isGhosting,
      orgImageUrl
    } = this.state;
    const {
      showSwitchOrganisationModal,
      selectedOrganisation,
      appSwitcherVisible,
      onCloseSwitchOrganisationModal,
      notifications,
      adminMode,
      toggleAdminMode,
      width,
      flags
    } = this.props;

    return (
      <header>
        <div className="column global-header-container">
          {this.isProWorkflow() && (
            <>
              <img
                className="workspace-logo"
                width={35}
                height={35}
                onClick={() => this.props.history.push('/home')}
                src={orgImageUrl || SHOOTSTA_LOGO_ICON}
              />
              <CreditsInfo
                openHubSpotConversationsWidget={openHubSpotConversationsWidget}
              />
            </>
          )}
          {this.renderContent()}
          {flags.featureX && <NotificationsSummary {...notifications} />}
          <UserWrapper
            userId={userId}
            fullName={fullName}
            imageUrl={imageUrl}
            isGhosting={isGhosting}
            email={email}
            handleSignOut={this.handleSignOut}
            handleStopGhosting={this.handleStopGhosting}
            showSwitchOrganisationModal={showSwitchOrganisationModal}
            selectedOrganisation={selectedOrganisation}
            adminMode={adminMode}
            toggleAdminMode={toggleAdminMode}
          />
          <SwitchOrganisationModal
            visible={appSwitcherVisible}
            onClose={onCloseSwitchOrganisationModal}
          />
        </div>
      </header>
    );
  }
}

export default withFlagsConsumer(GlobalHeader);
